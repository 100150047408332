import { useUser } from '~/composables/auth/use-customer-info'

export default defineNuxtRouteMiddleware(async () => {
  // eslint-disable-next-line node/prefer-global/process
  if (process.server)
    return

  const user = useUser()

  if (user.value)
    return navigateTo({ name: 'index' })
})
